// ./src/pages/blog.js
import { graphql } from "gatsby"
import React from "react"
import { injectIntl, navigate } from "gatsby-plugin-intl"
import { orderBy } from "lodash"

import { localize } from "../utils"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ProductLeaf from "../components/product-leaf"
import TopBar from "../components/topbar"
import SectionChar from "../components/section-char"

// The query used to provide the page data
export const pageQuery = graphql`
  query GatsbyProductsPageQuery($productLineSlug: String!, $slug: String!) {
    allDirectusBlock(filter: { page: { eq: "categoria" } }) {
      nodes {
        page
        order
        section
        type
        images {
          title
          private_hash
          localFile {
            childImageSharp {
              fixed(width: 1024, webpQuality: 100) {
                srcSet
                srcSetWebp
              }
            }
          }
        }
        data {
          body
          title
          lang
        }
      }
    }
    allDirectusProduct(
      filter: {
        category: {
          data: { elemMatch: { slug: { eq: $slug } } }
          product_line: {
            data: { elemMatch: { slug: { eq: $productLineSlug } } }
          }
        }
      }
    ) {
      nodes {
        directusId
        item_id
        hidden
        related_to
        size
        colors
        image {
          private_hash
          title
          localFile {
            childImageSharp {
              fixed(width: 260) {
                srcSet
                srcSetWebp
              }
            }
          }
        }
        data {
          lang
          name
          short_description
          long_description
          slug
        }
      }
    }
    directusCategory(data: {elemMatch: {slug: {eq: $slug}}}, product_line: {data: {elemMatch: {slug: {eq: $productLineSlug}}}}) {
      product_line {
        id
        key
      }
      directusId
      data {
        lang
        name
        description
      }
    }
  }
`

const CategoryPage = ({ data, intl }) => {
  const {
    allDirectusBlock: {nodes: blocks},
    allDirectusProduct: { nodes: products },
    directusCategory: category,
  } = data

  const locCategory = localize(category, intl.locale)
  const localizedBlocks = blocks.map(blockData =>
    localize(blockData, intl.locale)
  )

  const mainProducts = products
    .map(product => localize(product, intl.locale))
    .filter(product => !!product.short_description && !product.hidden)
  const secondaryProducts = products
    .map(product => localize(product, intl.locale))
    .filter(product => !product.short_description && !product.hidden)

  const categorySections = orderBy(localizedBlocks.filter(block => block.page === "categoria" && parseInt(block.section) === locCategory.directusId), 'order')

  return (
    <Layout name="p-category">
      <Seo title={locCategory.name} />
      <TopBar title={locCategory.name}>
        <p className="p-category__abstract">{locCategory.description}</p>
      </TopBar>
      <div className="a-container p-category-container">
        {categorySections.map(section => {
          return <SectionChar section={section}></SectionChar>
        })}
        {mainProducts.map(product => {
          return (
            <>
              <ProductLeaf
                item={{
                  ...product,
                  description: product.short_description,
                  extra: product.size,
                }}
                onClick={
                  product.long_description
                    ? () =>
                        navigate(`/prodotto/${product.item_id}`)
                    : undefined
                }
              />
            </>
          )
        })}
      </div>
      {secondaryProducts.length > 0 && (
        <div className="a-container p-category-others a-container--fluidMobile">
          <h4 className="a-section__title">
            {intl.formatMessage({ id: "terms.same_family" })}
          </h4>
          <div className="p-category-others__content">
            {secondaryProducts.map(product => {
              return (
                <>
                  <ProductLeaf
                    options={{ size: 165, compact: true }}
                    item={product}
                  />
                </>
              )
            })}
          </div>
        </div>
      )}
    </Layout>
  )
}

export default injectIntl(CategoryPage)
